/**
 * Contains full mapping of language code to country code.
 * 
 * @module fullLangRegionMap
 */


/* eslint-disable camelcase */
/**
 * Full mapping of language code to country code.
 */
const langRegionMap = {
    en: 'US',
    
    zh: 'CN',
    zh_hans: 'CN',
    hans: 'CN',   // script
    wuu: 'CN',
    hsn: 'CN',
    hak: 'CN',
    nan: 'CN',
    gan: 'CN',
    ii: 'CN',
    ug: 'CN',
    ug_arab: 'CN',
    za: 'CN',
    mn_mong: 'CN',
    bo: 'CN',
    kk_arab: 'CN',
    lis: 'CN',
    ky_arab: 'CN',
    nxq: 'CN',
    tdd: 'CN',
    khb: 'CN',
    lcp: 'CN',
    
    hi: 'IN',
    te: 'IN',
    mr: 'IN',
    ta: 'IN',
    gu: 'IN',
    kn: 'IN',
    or: 'IN',
    ml: 'IN',
    pa_guru: 'IN',
    bho: 'IN',
    awa: 'IN',
    as: 'IN',
    mwr: 'IN',
    mai: 'IN',
    mag: 'IN',
    bgc: 'IN',
    hne: 'IN',
    dcc: 'IN',
    bjj: 'IN',
    sat: 'IN',
    wtm: 'IN',
    ks: 'IN',
    ks_arab: 'IN',
    kok: 'IN',
    gom: 'IN',
    swv: 'IN',
    lmn: 'IN',
    gbm: 'IN',
    gon: 'IN',
    gon_telu: 'IN',
    kfy: 'IN',
    kru: 'IN',
    doi: 'IN',
    wbq: 'IN',
    sck: 'IN',
    xnr: 'IN',
    wbr: 'IN',
    tcy: 'IN',
    khn: 'IN',
    brx: 'IN',
    noe: 'IN',
    bhb: 'IN',
    mni: 'IN',
    raj: 'IN',
    hoc: 'IN',
    mtr: 'IN',
    unr: 'IN',
    unr_beng: 'IN',
    bhi: 'IN',
    hoj: 'IN',
    kha: 'IN',
    kfr: 'IN',
    grt: 'IN',
    unx: 'IN',
    unx_beng: 'IN',
    bfy: 'IN',
    srx: 'IN',
    saz: 'IN',
    sd_deva: 'IN',
    njo: 'IN',
    bjq: 'IN',
    ria: 'IN',
    bpy: 'IN',
    bra: 'IN',
    lep: 'IN',
    sa: 'IN',
    kht: 'IN',

    kgp: 'BR',
    gub: 'BR',
    yrl: 'BR',
    xav: 'BR',

    dz: 'BT',

    tn: 'BW',

    pdt: 'CA',
    crk: 'CA',
    cr: 'CA',
    cr_cans: 'CA',
    iu: 'CA',
    iu_cans: 'CA',
    iu_latn: 'CA',
    moe: 'CA',
    crj: 'CA',
    atj: 'CA',
    crl: 'CA',
    csw: 'CA',
    crm: 'CA',
    ikt: 'CA',
    moh: 'CA',
    dgr: 'CA',
    den: 'CA',
    scs: 'CA',
    nsk: 'CA',
    chp: 'CA',
    gwi: 'CA',

    guc: 'CO',

    am: 'ET',
    om: 'ET',
    ti: 'ET',
    sid: 'ET',
    wal: 'ET',
    aa: 'ET',

    quc: 'GT',

    ch: 'GU',

    id: 'ID',
    jv: 'ID',
    su: 'ID',
    mad: 'ID',
    ms_arab: 'ID',
    min: 'ID',
    bew: 'ID',
    ban: 'ID',
    bug: 'ID',
    bjn: 'ID',
    ace: 'ID',
    sas: 'ID',
    bbc: 'ID',
    mak: 'ID',
    ljp: 'ID',
    rej: 'ID',
    gor: 'ID',
    nij: 'ID',
    kge: 'ID',
    aoz: 'ID',
    kvr: 'ID',
    lbw: 'ID',
    gay: 'ID',
    rob: 'ID',
    sxn: 'ID',
    mdr: 'ID',
    sly: 'ID',
    mwv: 'ID',

    ga: 'IE',

    he: 'IL',
    lad: 'IL',

    jam: 'JM',

    ja: 'JP',
    ryu: 'JP',

    ki: 'KE',
    luy: 'KE',
    luo: 'KE',
    kln: 'KE',
    kam: 'KE',
    guz: 'KE',
    mer: 'KE',
    ebu: 'KE',
    dav: 'KE',
    pko: 'KE',
    saq: 'KE',

    km: 'KH',
    cja: 'KH',

    ko: 'KR',

    lo: 'LA',
    kjg: 'LA',

    mh: 'MH',

    my: 'MM',
    shn: 'MM',
    kac: 'MM',
    mnw: 'MM',

    mt: 'MT',

    yua: 'MX',
    nhe: 'MX',
    nhw: 'MX',
    maz: 'MX',
    nch: 'MX',
    sei: 'MX',

    vmw: 'MZ',
    ndc: 'MZ',
    ngl: 'MZ',
    seh: 'MZ',
    mgh: 'MZ',
    rng: 'MZ',
    yao: 'MZ',

    ne: 'NP',
    'new': 'NP',
    jml: 'NP',
    taj: 'NP',
    thl: 'NP',
    bap: 'NP',
    tdg: 'NP',
    thr: 'NP',
    mgp: 'NP',
    lif: 'NP',
    lif_deva: 'NP',
    thq: 'NP',
    mrd: 'NP',
    xsr: 'NP',
    rjs: 'NP',
    tsf: 'NP',
    ggn: 'NP',
    gvr: 'NP',
    tkt: 'NP',
    tdh: 'NP',
    unr_deva: 'NP',

    mi: 'NZ',

    qu: 'PE',

    fil: 'PH',
    ceb: 'PH',
    ilo: 'PH',
    hil: 'PH',
    bik: 'PH',
    war: 'PH',
    pam: 'PH',
    bhk: 'PH',
    pag: 'PH',
    mdh: 'PH',
    tsg: 'PH',
    cps: 'PH',
    krj: 'PH',
    bto: 'PH',
    hnn: 'PH',
    tbw: 'PH',
    bku: 'PH',

    ur: 'PK',
    pa: 'PK',
    pa_arab: 'PK',
    arab: 'PK',   // script
    lah: 'PK',
    ps: 'PK',
    sd: 'PK',
    sd_arab: 'PK',
    skr: 'PK',
    bal: 'PK',
    brh: 'PK',
    hno: 'PK',
    bgn: 'PK',
    hnd: 'PK',
    tg_arab: 'PK',
    gju: 'PK',
    bft: 'PK',
    kvx: 'PK',
    khw: 'PK',
    mvy: 'PK',
    kxp: 'PK',
    gjk: 'PK',
    btv: 'PK',

    gn: 'PY',

    th: 'TH',
    tts: 'TH',
    nod: 'TH',
    sou: 'TH',
    mfa: 'TH',
    kxm: 'TH',
    kdt: 'TH',
    lwl: 'TH',

    aeb: 'TN',

    zh_hant: 'TW',
    hant: 'TW',   // script
    trv: 'TW',

    nv: 'US',
    pdc: 'US',
    haw: 'US',
    frc: 'US',
    chr: 'US',
    esu: 'US',
    dak: 'US',
    cho: 'US',
    lkt: 'US',
    ik: 'US',
    mus: 'US',

    sm: 'WS',
    
    zu: 'ZA',
    xh: 'ZA',
    af: 'ZA',
    nso: 'ZA',
    st: 'ZA',
    ts: 'ZA',
    ss: 'ZA',
    ve: 'ZA',
    nr: 'ZA',

    sn: 'ZW',
    nd: 'ZW',
    mxc: 'ZW',
    kck: 'ZW',


    haz: 'AF',
    uz_arab: 'AF',

    arq: 'DZ',
    kab: 'DZ',

    ar: 'EG',
    arz: 'EG',

    ckb: 'IQ',
    syr: 'IQ',

    fa: 'IR',
    az_arab: 'IR',
    mzn: 'IR',
    glk: 'IR',
    sdh: 'IR',
    lrc: 'IR',
    rmt: 'IR',
    bqi: 'IR',
    luz: 'IR',
    lki: 'IR',
    prd: 'IR',
    gbz: 'IR',

    ary: 'MA',
    zgh: 'MA',
    tzm: 'MA',
    tzm_latn: 'MA',
    shi: 'MA',
    shi_latn: 'MA',
    shi_tfng: 'MA',
    tfng: 'MA',   // script
    rif: 'MA',
    rif_latn: 'MA',
    rif_tfng: 'MA',

    bej: 'SD',
    fvr: 'SD',
    mls: 'SD',
    fia: 'SD',
    zag: 'SD',


    bn: 'BD',
    rkt: 'BD',
    syl: 'BD',
    ccp: 'BD',

    dv: 'MV'
};
/* eslint-enable camelcase */

export default langRegionMap;
